<template>
  <RecordViewWrapper>
    <CardToolbox>
      <SearchHeaderStyle>
        <sdPageHeader title="Users">
          <template #subTitle>
            <a-input v-model:value="searchValue" @change="onHandleSearch" placeholder="Search by Name">
              <template #suffix>
                <sdFeatherIcons type="search" size="16" />
              </template>
            </a-input>
          </template>
          <template #buttons>
            <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
              <a to="#">+ Add New</a>
            </sdButton>
          </template>
        </sdPageHeader>
      </SearchHeaderStyle>
    </CardToolbox>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :dataSource="dataSource" :columns="columns" :loading="isLoading" />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
        <sdModal title="User's information" :visible="localState.visible" :footer="null" :onCancel="handleCancel">
          <div class="project-modal">
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                  :layout="formState.layout">
                  <a-row :gutter="25">
                    <a-col :md="24" :xs="24">
                      <a-form-item ref="firstName" label="Full name" name="firstName">
                        <a-input v-model:value="formState.firstName" placeholder="john Doe" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item ref="userName" label="User name" name="userName">
                        <a-input :disabled="formState.editionMode" v-model:value="formState.userName"
                          placeholder="User name" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item ref="email" label="Email" name="email">
                        <a-input :disabled="formState.editionMode" v-model:value="formState.email" placeholder="Email" />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="!formState.editionMode" :md="12" :xs="24">
                      <a-form-item name="password" label="Password">
                        <a-input-password v-model:value="formState.password" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="!formState.editionMode" :md="12" :xs="24">
                      <a-form-item name="confirmationPassword" label="confirmation password">
                        <a-input-password v-model:value="formState.confirmationPassword" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item name="fonction" label="Contact function ">
                        <a-input v-model:value="formState.fonction" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="12"><a-form-item label="Role" name="roles">
                        <a-select :size="'small'" mode="multiple" v-model:value="formState.roles"
                          placeholder="Please choose user roles">
                          <a-select-option value="Basic">Basic</a-select-option>
                          <a-select-option value="Admin">Admin</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24"><a-form-item label="Partner" name="partenaireId">
                        <a-select v-if="partnerApi.length" v-model:value="formState.partenaireId"
                          placeholder="Please choose Owner" show-search style="width: 100%" :options="partnerData"
                          :not-found-content="partnerFetching ? undefined : null
                            " :filter-option="false" @search="searchPartner">
                          <template v-if="partnerFetching" #notFoundContent>
                            <a-spin size="small" />
                          </template>
                        </a-select>
                        <a-select v-else v-model:value="formState.partenaireId" placeholder="Please choose Owner">
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :xs="24" :md="24">
                      <a-form-item label="Note">
                        <a-textarea v-model:value="formState.note" :style="{ width: '100%' }" placeholder="Note ..."
                          name="address" class="sDash_unresizable" />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="24" :md="24">
                      <sdButton htmlType="submit" size="full" type="primary" key="submit">
                        <span v-if="!formState.editionMode">Add new</span>
                        <span v-if="formState.editionMode">Edit user</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-form>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </div>
        </sdModal>
        <sdModal title="Edit password" :visible="passwordFormState.visible" :footer="null" :onCancel="handleCancel">
          <div class="project-modal">
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmitPassword" :model="passwordFormState"
                  :rules="passwordRules" :layout="passwordFormState.layout">
                  <a-row :gutter="25">


                    <a-col :md="24" :xs="24">
                      <a-form-item ref="password" label="Password" name="password">
                        <a-input-password v-model:value="passwordFormState.password" placeholder="Password" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24">
                      <a-form-item name="newPassword" label="New Password">
                        <a-input-password v-model:value="passwordFormState.newPassword" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24">
                      <a-form-item name="confirmNewPassword" label="Confirm new password">
                        <a-input-password v-model:value="passwordFormState.confirmNewPassword"
                          :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="24" :md="24">
                      <sdButton htmlType="submit" size="full" type="primary" key="submit">
                        <span>Edit Password</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-form>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </div>
        </sdModal>
      </a-row>
    </Main>
  </RecordViewWrapper>
  <UserPemssionModal v-if="permissionModalOpen" :userId="selectedUserId" @onCancel="showHidePermissionModal"></UserPemssionModal>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { RecordViewWrapper } from "./style";
import { useStore } from "vuex";
import { defineAsyncComponent, computed, onMounted, reactive, ref } from "vue";
import usePartnerSearch from "../../composable/usePartnerSearch";
import { onBeforeRouteLeave } from "vue-router";


const UserPemssionModal = defineAsyncComponent(() =>
  import("./UserPermissionModal.vue")
);
const columns = [
  {
    title: "Full name",
    dataIndex: "contact.firstName",
    key: "firstName",
    //sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "User name",
    dataIndex: "contact.userName",
    key: "contact.userName",
  },
  {
    title: "Email",
    dataIndex: "contact.email",
    key: "contact.email",
  },
  // {
  //   title: 'Contact number',
  //   dataIndex: 'contact.phoneNumber',
  //   key: 'contact.phoneNumber',
  // },
  // {
  //   title: 'Address',
  //   dataIndex: 'contact.address',
  //   key: 'contact.address',
  // },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Partners = {
  name: "Partners",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    RecordViewWrapper,
    CardToolbox,
    SearchHeaderStyle,
    UserPemssionModal
  },
  setup() {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const contactApi = computed(() => state.contactApi.data);
    const isLoading = computed(() => state.contactApi.loading);
    const partnerApi = computed(() => state.partnerApi.data);

    var loading = false;
    var formState = reactive({
      layout: "vertical",
      id: 0,
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmationPassword: "",
      phoneNumber: "",
      partenaireId: "",
      fonction: "",
      roles: [],
    });
    var passwordFormState = reactive({
      visible: false,
      layout: "vertical",
      userId: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const formRef = ref();
    const selectedUserId = ref("");

    let validatePass = async (rules, value) => {
      if (value === "") {
        return Promise.reject("Please input the password again");
      } else if (value !== formState.password) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };
    let validateEditPass = async (rules, value) => {
      if (value === "") {
        return Promise.reject("Please input the password again");
      } else if (value !== passwordFormState.newPassword) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      firstName: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      userName: [
        {
          required: true,
          message: "UserName is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Mail is required",
          trigger: "change",
        },
      ],
      partenaireId: [
        {
          required: true,
          message: "Partner is required",
          trigger: "change",
        },
      ],
      roles: [
        {
          type: "array",
          required: true,
          message: "Role is required",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: "change",
        },
      ],
      confirmationPassword: [
        { required: true, validator: validatePass, trigger: "change" },
      ],
    };
    const passwordRules = {
      password: [
        {
          required: true,
          message: "Old password is required",
          trigger: "change",
        },
      ],
      newPassword: [
        {
          required: true,
          message: "new password is required",
          trigger: "change",
        },
      ],
      confirmNewPassword: [
        { required: true, validator: validateEditPass, trigger: "change" },
      ],
    };

    onMounted(() => {
      let filters = {
        UserName: "",
        PartenaireId: "",
      };
      dispatch("contactGetAllData", filters);
      dispatch("partnerInfo");
    });

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.firstName = "";
      formState.userName = "";
      formState.email = "";
      formState.password = "";
      formState.confirmationPassword = "";
      formState.phoneNumber = "";
      formState.partenaireId = null;
      formState.fonction = "";
      formState.roles = [];
      formState.note = "";
      localState.visible = true;
    };

    const showPasswordModal = (userId) => {
      passwordFormState.userId = userId;
      passwordFormState.password = "";
      passwordFormState.newPassword = "";
      passwordFormState.confirmNewPassword = "";
      passwordFormState.visible = true;
    };

    const handleDelete = (partnerData) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("contactApiDataDelete", {
          id: partnerData.id,
        });
      }
      return false;
    };
    const handleEdit = (contact) => {
      partnerData.value.push({
        value: contact.partenaireId,
        label: contact.partenaireName,
      });

      formState.editionMode = true;
      delete formState.password;
      delete formState.confirmationPassword;
      formState.id = contact.id;
      formState.firstName = contact.firstName;
      formState.userName = contact.userName;
      formState.fonction = contact.fonction;
      formState.email = contact.email;
      formState.phoneNumber = contact.phoneNumber;
      formState.note = contact.note;
      formState.partenaireId = contact.partenaireId;
      formState.roles = contact.roles;
      localState.visible = true;
    };

    const onHandleSearch = () => {
      let filters = {
        UserName: searchValue.value,
        PartenaireId: "",
      };
      dispatch("contactGetAllData", filters);
    };

    const dataSource = computed(() =>
      contactApi.value && contactApi.value.length
        ? contactApi.value.map((contact, key) => {
          return {
            key: key + 1,
            contact,
            action: (
              <div class="table-actions">
                <a-button class="edit" onClick={() => handleEdit(contact)}>
                  <sdFeatherIcons type="edit" size={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button class="edit" onClick={() => showPasswordModal(contact.id)}>
                  <sdFeatherIcons type="lock" size={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button class="edit" onClick={() => showHidePermissionModal(contact.id)}>
                  <sdFeatherIcons type="alert-octagon" size={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button
                  class="delete"
                  onClick={() => handleDelete(contact)}
                >
                  <sdFeatherIcons type="trash-2" size={14} />
                </a-button>
              </div>
            ),
          };
        })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!formState.editionMode) {
            dispatch("contactSubmitData", {
              formState,
            });
          } else {
            dispatch("contactUpdateData", {
              formState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onSubmitPassword = () => {
      formRef.value
        .validate()
        .then(() => {
          dispatch("contactUpdatePassword", {
            passwordFormState,
          });
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      passwordFormState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };
    const { partnerData, searchPartner } = usePartnerSearch(
      formState.partenaireId
    );
    // Permission Logic
    const permissionModalOpen = ref(false);
    const showHidePermissionModal = (userId) => {
      selectedUserId.value = userId;
      permissionModalOpen.value = !permissionModalOpen.value
    }
    onBeforeRouteLeave(() => {
      dispatch("emptyUsersData");
    });
    return {
      partnerData,
      searchPartner,
      contactApi,
      formRef,
      rules,
      passwordRules,
      onSubmit,
      onSubmitPassword,
      isLoading,
      handleDelete,
      handleEdit,
      dataSource,
      columns,
      formState,
      localState,
      showModal,
      handleCancel,
      onHandleSearch,
      partnerApi,
      loading,
      searchValue,
      showPasswordModal,
      passwordFormState,
      permissionModalOpen,
      showHidePermissionModal,
      selectedUserId
    };
  },
};

export default Partners;
</script>
